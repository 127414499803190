// TODO: 작은 사이즈 확장 + 셀렉트 사이즈 네이밍 통일을 위해 naming 변경이 어떨지..? -> 260(large), 180(medium), numeric 작은 사이즈(small)
export const AimInputSize = {
  extraSmall: 'extra-small',
  small: 'small',
  medium: 'medium',
  fill: 'fill',
  fillLarge: 'fill-large'
} as const;
export type AimInputSizeType = typeof AimInputSize[keyof typeof AimInputSize];

export const AimInputState = {
  default: 'default',
  warning: 'warning',
  error: 'error',
} as const;
export type AimInputStateType = keyof typeof AimInputState;

export const NumericInputOption = {
  spinner: 'spinner',
  center: 'center',
  default: 'default',
} as const;
export type NumericInputOptionType = keyof typeof NumericInputOption;

export const AimInputTypeName = {
  button: 'button',
  checkbox: 'checkbox',
  color: 'color',
  date: 'date',
  'datetime-local': 'datetime-local',
  email: 'email',
  file: 'file',
  hidden: 'hidden',
  image: 'image',
  month: 'month',
  number: 'number',
  password: 'password',
  radio: 'radio',
  range: 'range',
  reset: 'reset',
  search: 'search',
  submit: 'submit',
  tel: 'tel',
  text: 'text',
  time: 'time',
  url: 'url',
  week: 'week',
} as const;
export type AimInputType = keyof typeof AimInputTypeName;

export const NEGATIVE_SIGN = '-';
export const POSITIVE_SIGN = '+';
export const EXPONENT = 'e';
